<template>
  <footer>
    <div class="footer">
      <div class="footer__wrapper">
        <div class="footer__info">
          <a href="/" class="footer__logo"></a>
          <p class="footer__address">
            606400, Нижегородская обл.,<br />
            г. Балахна, пр. Дзержинского, 1/13, офис 12.
            ОГРН 3215275000035571
          </p>
          <p class="footer__description">
            ЕГРН СЕРВИС (EGRNSERVICE.RU) не относится к сервисам самого Росреестра и не имеет отношения ни к Росреестру ни к его сервисам. ЕГРН СЕРВИС (EGRNSERVICE.RU) не формирует и не подписывает выписки. В соответствии с действующими нормами только Росреестр формирует и подписывает выписки. ЕГРН СЕРВИС (EGRNSERVICE.RU) оказывает услуги по использованию своего программного обеспечения для формирования отчетов об объектах недвижимости.
          </p>
          <p class="footer__copyright">
            ©EGRNSERVICE, 2022
          </p>
        </div>
        <div>
          <Button class="footer__get-order" @click.native="checkRoute">
            Заказать отчет
          </Button>
          <ul class="footer__contacts">
            <li class="footer__phone">
              <a href="tel:+74952410152">Поддержка: +7 495 241 01 52</a>
            </li>
            <li class="footer__phone">
              <a href="tel:+74994907063">Юрист: +7 499 490 70 63</a>
            </li>
            <li class="footer__mail">
              <a href="mailto:support@egrnservice.ru">support@egrnservice.ru</a>
            </li>
          </ul>
          <div class="footer__payments">
            <img v-for="icon in payment" :key="icon" :src="icon" />
          </div>
        </div>
      </div>
    </div>
    <div class="sub-footer">
      <div class="sub-footer__wrapper">
        <p>
          Совершая любые действия на сайте, вы даете свое
          <a href="/personal-data" target="_blank">
            Согласие на обработку персональных данных
          </a>
          .
          <br />
          Вы ознакомились и соглашаетесь с <a href="/public-offer" target="_blank">Договором публичной оферты</a> и
          <a href="/privacy-policy" target="_blank">Политикой Конфиденциальности</a>.
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import './footer.scss';

export default {
  name: 'IFooter',
  components: {
    Button: () => import('@/components/gui/button/Button')
  },
  data() {
    return {
      interval: null,
      payment: [
        require('@/assets/payment-icons/master-card.svg'),
        require('@/assets/payment-icons/maestro.svg'),
        require('@/assets/payment-icons/mir.svg'),
        require('@/assets/payment-icons/visa.svg')
      ]
    };
  },
  methods: {
    checkRoute() {
      if (this.$route.name === 'Main') {
        this.order();
      } else {
        this.$router.push('/');
        this.interval = setInterval(() => {
          if (document.querySelector('#address input') !== null) {
            this.order();
          }
        }, 100);
      }
    },
    order() {
      if (this.interval !== null) {
        clearInterval(this.interval);
      }

      document.querySelector('#address input').focus();
    }
  }
};
</script>
